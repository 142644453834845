enum ERoutes {
  HOME = '/',

  AUTH = '/auth',
  AUTH_ALL = '/auth/*',
  AUTH_SIGNUP = '/auth/signup',
  AUTH_LOGIN = '/auth/login',
  LOGOUT = 'logout',
  AUTH_PASSWORD_CHANGE = '/auth/password-recovery',
  AUTH_ACCOUNT_ACTIVATION = '/auth/account-activation',
  AUTH_VALIDATE_ACCOUNT = '/auth/validate-account',

  AUTH_PASSWORD_RECOVERY_REQUEST = '/auth/forgot-password',

  ERROR_ALL = '/error/*',
  ALL = '/*',

  DASHBOARD = '/dashboard',

  BOOKING = '/bookings',
  BOOKING_ALL = '/bookings/*',
  BOOKING_INFORMATION = '/bookings/:id/information',
  BOOKING_TALENTS = '/bookings/:id/talents',
  BOOKING_TEAM = '/bookings/:id/team',
  BOOKING_TRAVEL = '/bookings/:id/travel',
  BOOKING_DOCUMENTS = '/bookings/:id/documents',
  BOOKING_ACTIVITY = '/bookings/:id/activity',

  PACKAGES = '/packages',
  PACKAGES_ALL = '/packages/*',
  PACKAGES_INFORMATION = '/packages/:id/information',
  PACKAGES_TALENTS = '/packages/:id/talents',
  PACKAGES_OPTIONS = '/packages/:id/options',
  PACKAGES_NOTES = '/packages/:id/notes',
  PACKAGES_MESSAGES = '/packages/:id/messages',
  PACKAGES_RECIPIENTS = '/packages/:id/recipients',

  TALENTS = '/talents',
  TALENTS_ALL = '/talents/*',
  TALENTS_INFORMATION = '/talents/:id/information',
  TALENTS_CHARACTERISTICS = '/talents/:id/characteristics',
  TALENTS_ASPECTS = '/talents/:id/aspects',
  TALENTS_SKILLS = '/talents/:id/skills',
  TALENTS_SOCIAL_MEDIA = '/talents/:id/social-media',
  TALENTS_PACKAGES = '/talents/:id/packages',
  TALENTS_BOOKINGS = '/talents/:id/bookings',
  TALENTS_DOCUMENTS = '/talents/:id/documents',
  TALENTS_ACTIVITY = '/talents/:id/activity',
  TALENTS_MEDIA = '/talents/:id/media',

  CUSTOMERS = '/customers',
  CUSTOMERS_ALL = '/customers/*',
  CUSTOMERS_INFORMATION = '/customers/:id/information',
  CUSTOMERS_CONTACTS = '/customers/:id/contacts',
  CUSTOMERS_PACKAGES = '/customers/:id/package',
  CUSTOMERS_BOOKINGS = '/customers/:id/booking',
  CUSTOMERS_TALENTS = '/customers/:id/talent',

  USERS = '/users',
  USERS_ALL = '/users/*',
  USERS_INFORMATION = '/users/:id/information',

  ROLES = '/roles',
  ROLES_ALL = '/roles/*',
  ROLES_INFORMATION = '/roles/:id/information',

  PUBLIC = '/p',
  PUBLIC_ALL = '/p/*',
  PUBLIC_PACKAGES = '/company/:cid/packages/:id/:email',

  MY_AGENCY = '/my-agency',

  SEARCHER = '/searcher',

  NEW_PARAMETER = 'new'

}

export default ERoutes;
