import {createSlice, PayloadAction, UnknownAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import IEvent from "../../interfaces/IEvent.ts";
import IPackage from "../../interfaces/IPackage.ts";
import IPageData from "../../interfaces/IPageData.ts";

type IStore = {
  package?: IPackage
  completed?: string;
  save?: boolean
  pageData: {
    [key: string]: IPageData
  }
  guestEmail?: string
}

const initialState: IStore = {
  save: false,
  pageData: {}
};

const store = createSlice({
  name: EStores.PACKAGE,
  initialState,
  reducers: {
    setPackage(state: IStore, data: PayloadAction<IPackage>) {
      state.package = data.payload;

      if (state.package) {
        const values: boolean[] = [
          (state?.package?.recipients || []).length > 0,
          (state?.package?.messages || []).length > 0,
          (state?.package?.talents || []).length > 0,
          state?.package?.internalNotes !== '',
          state?.package?.name !== '',
        ]

        const cmp = values.filter((value) => value).length
        if (!values.length) {
          state.completed = '0'
          return;
        }

        state.completed = (cmp / values.length * 100).toFixed(1)
      }

      return
    },
    clearPackage(state: IStore) {
      state.package = undefined;
      state.completed = '0';
      state.save = false;
      state.pageData = {};
      return
    },
    setPackageValues(state: IStore, data: PayloadAction<IPageData>) {
      state.pageData[data.payload.key] = data.payload;
      return
    },
    savePackage(state: IStore) {
      state.save = true
      return
    },
    setSavePackageFalse(state: IStore) {
      state.save = false
      return
    },
    setGuestEmail(state: IStore, data: PayloadAction<string>) {
      state.guestEmail = data.payload
      return
    }
  },
});

export const {
  setPackage,
  clearPackage,
  savePackage,
  setSavePackageFalse,
  setPackageValues,
  setGuestEmail,
} = store.actions;

export default store.reducer;
