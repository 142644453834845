import IFilterOption from "../../../../app/core/interfaces/IFilterOption.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/core/store/store.ts";
import EFilterOptionType from "../../../../app/core/enums/EFilterOptionType.ts";
import { setFilterValue } from "../../../../app/core/store/slices/searcher.ts";
import { useIntl } from "react-intl";
import RCInputRangeText from "../../../../app/utils/RCInputRangeText.tsx";
import RCInputRange from "../../../../app/utils/RCInputRange.tsx";
import { ulid } from "ulid";
import Select from 'react-select'

export interface IComponents {
  options: IFilterOption[]
}

export default function (props: IComponents) {
  const dispatch = useDispatch();
  const filters = useSelector((state: RootState) => state.searcher)
  const intl = useIntl()

  const onChange = (
    type: EFilterOptionType,
    key: string,
    value: string | string[] | number | number[] | any
  ) => {
    dispatch(setFilterValue({
      key,
      value,
    }))
  }

  return <>
    {
      props.options.map((option) => {
        if (option.show && !option.show(filters.filter)) return <div key={option.name}></div>

        switch (option.type) {
          case EFilterOptionType.SELECT_MANY:
            const optionsObj = (option.values || [])?.map(value => ({ value: value, label: intl.formatMessage({ id: value }) }));
            const selectedOptions = filters.filter[option.field]
            return <div className={'mb-5'} key={option.name}>
              <label className="fs-6 fw-semibold mb-2">{intl.formatMessage({ id: option.name })}:</label>

              <Select
                className='react-select-styled react-select-solid react-select-lg'
                classNamePrefix='react-select'
                options={optionsObj}
                placeholder={'Seleccione'}
                value={selectedOptions ? optionsObj.filter(option => selectedOptions?.includes(option.value)) : []}
                onChange={(selected) => {
                  const selectedValues = selected ? selected.map(option => option.value) : []
                  onChange(
                    EFilterOptionType.SELECT_MANY,
                    option.field,
                    selectedValues,
                  )
                }}
                isMulti
              />

            </div>
          case EFilterOptionType.SELECT_ONE:
            return <div className={'mb-5'} key={option.name}>
              <label className="fs-6 fw-semibold mb-2">{intl.formatMessage({ id: option.name })}:</label>

              <select
                className={'form-control form-control-solid'}
                value={filters.filter[option.field] || ''}
                onChange={(e) => {
                  onChange(
                    EFilterOptionType.SELECT_ONE,
                    option.field,
                    e.target.value,
                  )
                }}
              >
                <option key={ulid()} value={''}>Seleccione</option>
                {(option.values || [])?.map((st) => {
                  return <option key={st} value={st}>{intl.formatMessage({ id: st })}</option>
                })
                }
              </select>
            </div>
          case EFilterOptionType.BOOLEAN:
            return <div className={'ps-0 form-check form-switch mb-5 d-flex justify-content-between'} key={option.name}>
              <label className="fs-6 fw-semibold mb-2">{intl.formatMessage({ id: option.name })}:</label>

              <input
                type={'checkbox'}
                role={'switch'}
                checked={filters.filter[option.field] || false}
                className={'form-check-input'}
                onChange={(e) => {
                  onChange(EFilterOptionType.BOOLEAN, option.field, e.target.checked)
                }}
              />
            </div>
          case EFilterOptionType.SWITCH:
            return <div className={'ps-0 form-check form-switch mb-5 d-flex justify-content-between'} key={option.name}>
              <label className="fs-6 fw-semibold mb-2">{intl.formatMessage({ id: option.name })}:</label>

              <input
                type={'checkbox'}
                role={'switch'}
                checked={filters.filter[option.field] || false}
                className={'form-check-input'}
                onChange={(e) => {
                  onChange(EFilterOptionType.SWITCH, option.field, e.target.checked)
                }}
              />
            </div>
          case EFilterOptionType.NUMBER_RANGE:
            return <div className={'mb-5'} key={option.name}>
              <label className="fs-6 fw-semibold mb-2">{intl.formatMessage({ id: option.name })}:</label>

              <RCInputRange
                min={option.min || 0}
                max={option.max || 100}
                minValue={filters.filter[option.field] || option.min || 0}
                maxValue={filters.filter[`${option.field}Max`] || option.max || 1}
                unity={option.unity || ''}
                secondaryUnity={option.secondaryUnity}
                onChange={(min, max) => {
                  onChange(EFilterOptionType.NUMBER_RANGE, option.field, min)
                  onChange(EFilterOptionType.NUMBER_RANGE, `${option.field}Max`, max)
                }}
              />
            </div>
          case EFilterOptionType.TEXT_RANGE:
            return <div className={'mb-5'} key={option.name}>
              <label className="fs-6 fw-semibold mb-2">{intl.formatMessage({ id: option.name })}:</label>

              <RCInputRangeText
                steps={option.values || []}
                minValue={filters.filter[option.field] || ''}
                maxValue={filters.filter[`${option.field}Max`] || ''}
                onChange={(min, max) => {
                  onChange(EFilterOptionType.TEXT_RANGE, option.field, min)
                  onChange(EFilterOptionType.TEXT_RANGE, `${option.field}Max`, max)
                }}
              />
            </div>
          case EFilterOptionType.DATE:
            return <div className={'mb-5'} key={option.name}>
              <label className="fs-6 fw-semibold mb-2">{intl.formatMessage({ id: option.name })}:</label>

              <input
                type={'date'}
                value={filters.filter[option.field] || ''}
                onChange={(e) => {
                  onChange(EFilterOptionType.DATE, option.field, e.target.value)
                }}
                className={'form-control form-control-solid'}
              />
            </div>
          default:
            return <div key={option.name}></div>
        }
      })
    }
  </>
}
