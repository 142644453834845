import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import { setPackage } from "../../../../store/slices/package";
import requestMiddlewares from "../../../requestMiddlewares";

export default async function (
  dispatch: Dispatch,
  companyId: any,
  packageId: string,
  talentId: string,
  status: string,
  recipientEmail: string,
): Promise<IResponse> {
  
  if (!companyId) {
    return {
      statusCode: 500,
      data: {
        message: 'No company selected'
      } 
    }
  }
  //dispatch(startLoading()); 

  const request = new RACARequest('companyPackages', 'updateTalentStatus')
    .setPathValues('COMPANY_ID', companyId)
    .setPathValues('ID', packageId)
    .setPathValues('TALENT_ID', talentId)
    .setData({
        status,
        recipientEmail
    });

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  if (response.statusCode === 200) {
    // Despacha la acción para actualizar el estado del talento en Redux
    dispatch(setPackage(response.data)); 
  }

  //dispatch(endLoading());

  return response;
}
