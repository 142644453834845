import {FC, useEffect, useState} from 'react'
import {useIntl} from "react-intl";
import { useDispatch, useSelector } from 'react-redux';
import { PackageSidebar } from './Sidebar/PackageSidebar';
import { RootState } from '../../../core/store/store';
import { useParams } from 'react-router-dom';
import findCompanyPackageByIdRequest from '../../../core/api/requests/companies/package/findCompanyPackageByIdRequest';
import {setGuestEmail, setPackage} from "../../../core/store/slices/package.ts";
import ITalent from '../../../core/interfaces/ITalent.ts';
import TalentBigButton from '../../../utils/TalentBigButton';
import ModalTalentDetails from './Modal/ModalTalentDetails.tsx';

const PackageView: FC = () => {
    const [talents, setTalents] = useState<ITalent[]>([])
    const [selectedTalent, setSelectedTalent] = useState<ITalent | null>(null)
    const intl = useIntl()
    const dispatch = useDispatch()
    const params = useParams()

    const pkg = useSelector((state: RootState) => state.package)

    const findByID = async (id: string, companyId: string) => {
        if (!id) return

        const response = await findCompanyPackageByIdRequest(
            dispatch,
            id,
            companyId
        )

        if (response.statusCode !== 200) return
        dispatch(setPackage(response.data))
    }

    const selectTalent = (talent:ITalent) => {
        setSelectedTalent(talent)
    }

    useEffect(() => {
        if(pkg?.package?.talents){
            let talentArray = (pkg?.package?.talents || []).map((talent) => {
                return {
                  ...talent.user,
                  status: talent.status,
                  images: talent.images
                }
              })
            setTalents(talentArray)
        }
    },[pkg])

    useEffect(() => {
        if (params.id && params.cid && params.email) {
            findByID(params.id, params.cid)

            const decodedEmail = atob(params.email)
            dispatch(setGuestEmail(decodedEmail))
        }
    }, [])

  return (
    <>
        <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <PackageSidebar />

            <div className='app-main public flex-column flex-row-fluid p-12' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>                    
                    {talents.length > 0 &&
                        <div className={'row gx-1'}>
                        {(talents || []).map((talent) => {
                            return <TalentBigButton
                            key={talent.id}
                            talent={talent}
                            companyId={params.cid}
                            packageId={params.id}
                            showDetails={true}
                            allowSelect={true}
                            onSelect={() => selectTalent(talent)}
                            />
                        })}
                        </div>
                    }
                </div>
            </div>

            {!!selectedTalent &&
                <ModalTalentDetails
                    show={!!selectedTalent}
                    onClose={() => setSelectedTalent(null)}
                    selectedTalent={selectedTalent}
                />
            }
        </div>
    </>
    
  )
}

export {PackageView}
