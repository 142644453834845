

import { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useIntl } from "react-intl";
import ComingSoon from "../../../../_metronic/partials/ComingSoon.tsx";
import { ISignUpRequest } from '../../../core/api/requests/auth/signUpRequest.ts'
import signUpRequest from '../../../core/api/requests/auth/signUpRequest.ts'
import { useDispatch } from 'react-redux'
import EHttpStatusCodes from '../../../core/enums/EHttpStatusCodes.ts'
import ERoutes from '../../../core/enums/ERoutes.ts'
import { useState } from 'react'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

export function Registration() {
  const intl = useIntl()

  const dispatch = useDispatch()

  const [errorAlert, setErrorAlert] = useState<string|null>(null)

  const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN_3_CHARS' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX_50_CHARS' }))
      .required(intl.formatMessage({ id: 'VALIDATION.COMMON_REQUIRED' })),
    lastname: Yup.string()
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN_3_CHARS' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX_50_CHARS' }))
      .required(intl.formatMessage({ id: 'VALIDATION.COMMON_REQUIRED' })),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'VALIDATION.EMAIL_INVALID' }))
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN_3_CHARS' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX_50_CHARS' }))
      .required(intl.formatMessage({ id: 'VALIDATION.COMMON_REQUIRED' })),
    password: Yup.string()
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN_3_CHARS' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX_50_CHARS' }))
      .required(intl.formatMessage({ id: 'VALIDATION.COMMON_REQUIRED' })),
    changepassword: Yup.string()
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN_3_CHARS' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX_50_CHARS' }))
      .required(intl.formatMessage({ id: 'VALIDATION.COMMON_REQUIRED' }))
      .oneOf([Yup.ref('password')], intl.formatMessage({ id: 'VALIDATION.PASSWORDS_MUST_MATCH' })),
    acceptTerms: Yup.bool().required(intl.formatMessage({ id: 'VALIDATION.COMMON_REQUIRED' })),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {

      const data: ISignUpRequest = {
        name: values.firstname,
        lastName: values.lastname,
        email: values.email,
        password: values.password,
      }

      const response = await signUpRequest(dispatch, data)

      if (response.statusCode === EHttpStatusCodes.OK) {
        window.open(ERoutes.AUTH_VALIDATE_ACCOUNT, '_self')
      } else {
        setErrorAlert(intl.formatMessage({ id: response.data.code }))
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
      autoComplete='off'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-gray-900 fw-bolder mb-3'>{intl.formatMessage({ id: 'TITLES.SIGN_UP' })}</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>{intl.formatMessage({ id: 'SIGN_UP.SOCIAL_ACCESS' })}
          <ComingSoon className={'ms-2'} />
        </div>
      </div>
      {/* end::Heading */}

      {/* begin::Login options */}
      <div className='row g-3 mb-9'>
        {/* begin::Col */}
        <div className='col-md-6'>
          {/* begin::Google link */}
          <a
            href='#'
            className='disabled btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            {intl.formatMessage({ id: 'SIGN_UP.SIGN_UP_GOOGLE' })}
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-md-6'>
          {/* begin::Google link */}
          <a
            href='#'
            className='disabled btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            {intl.formatMessage({ id: 'SIGN_UP.SIGN_UP_APPLE' })}
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Login options */}

      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>{intl.formatMessage({ id: 'SIGN_UP.OR_WITH_EMAIL' })}</span>
      </div>

      {errorAlert && 
        <div className="mb-lg-15 alert text-danger bg-light-danger">
          <div className="alert-text font-weight-bold">{errorAlert}</div>
        </div>
      }

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({ id: 'PLACEHOLDERS.FIRST_NAME' })}</label>
        <input
          placeholder={intl.formatMessage({ id: 'PLACEHOLDERS.FIRST_NAME' })}
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.firstname && formik.errors.firstname,
            },
            {
              'is-valid': formik.touched.firstname && !formik.errors.firstname,
            }
          )}
        />
        {formik.touched.firstname && formik.errors.firstname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstname}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({ id: 'PLACEHOLDERS.LAST_NAME' })}</label>
        <input
          placeholder={intl.formatMessage({ id: 'PLACEHOLDERS.LAST_NAME' })}
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.lastname && formik.errors.lastname,
            },
            {
              'is-valid': formik.touched.lastname && !formik.errors.lastname,
            }
          )}
        />
        {formik.touched.lastname && formik.errors.lastname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastname}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({ id: 'PLACEHOLDERS.EMAIL' })}</label>
        <input
          placeholder={intl.formatMessage({ id: 'PLACEHOLDERS.EMAIL' })}
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({ id: 'PLACEHOLDERS.PASSWORD' })}</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder={intl.formatMessage({ id: 'PLACEHOLDERS.PASSWORD' })}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          {intl.formatMessage({ id: 'COMMON.PASSWORD_NEEDS' })}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({ id: 'PLACEHOLDERS.PASSWORD_REPEAT' })}</label>
        <input
          type='password'
          placeholder={intl.formatMessage({ id: 'PLACEHOLDERS.PASSWORD_REPEAT' })}
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            {intl.formatMessage({ id: 'PLACEHOLDERS.I_ACCEPT_THE' })}{' '}
            <a
              href='https://keenthemes.com/metronic/?page=faq'
              target='_blank'
              className='ms-1 link-primary'
            >
              {intl.formatMessage({ id: 'PLACEHOLDERS.TERMS' })}
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          <span className='indicator-label'>{intl.formatMessage({ id: 'PLACEHOLDERS.SUBMIT' })}</span>
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            {intl.formatMessage({ id: 'PLACEHOLDERS.CANCEL' })}
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
