

import { KTIcon } from '../../../helpers'
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/core/store/store.ts";
import React, { useState } from "react";
import { clearFilter, setCommonSearch, setFilterValue } from "../../../../app/core/store/slices/searcher.ts";
import FilterOption from "./FilterOption.tsx";

const SearchDrawer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const futils = useSelector((state: RootState) => state.frontendUtilities)
  const searcherSlice = useSelector((state: RootState) => state.searcher)

  const [tabBar, setTabBar] = useState<string[]>([])

  const [openSubSections, setOpenSubSections] = useState<string[]>([])

  const clear = () => {
    dispatch(clearFilter())
  }

  const setQueryFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (!window.location.hash.includes('showSearcher')) {
      dispatch(setCommonSearch(value))
    } else {
      dispatch(setFilterValue({
        key: 'query',
        value: value || '',
      }))
    }

  }

  return (
    <div
      id='kt_help'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='help'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_help_toggle'
      data-kt-drawer-close='#kt_help_close'
    >
      <div className='card shadow-none rounded-0 w-100'>
        <div className='card-header position-sticky top-0 bg-white z-index-2' id='kt_help_header'>
          <h5 className='card-title fw-bold text-gray-600'>
            {intl.formatMessage({ id: 'MENU.TALENT_SEARCHER' })}
          </h5>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_help_close'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </button>
          </div>
        </div>

        <div className='card-body' id='kt_help_body'>
          <div className={'row mb-5'}>
            <div className={'col-12'}>
              <input
                type='text'
                value={searcherSlice.filter.query || searcherSlice.commonSearch || ''}
                onChange={(e) => setQueryFilter(e)}
                className='form-control form-control-solid form-control-lg'
                placeholder={intl.formatMessage({ id: 'PLACEHOLDERS.TALENT_SEARCH' })}
              />
            </div>
          </div>

          <div>
            {futils?.filter?.map((filter) => {
              return <div
                key={filter.name}
                className={`accordion ${tabBar.includes(filter.name) ? 'accordion-icon-toggle' : ''}`}>
                <div className="mb-5">
                  <div
                    className="accordion-header py-3 d-flex collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${filter.name}`}>
                    <span className="accordion-icon">
                      <i className="ki-duotone ki-arrow-right fs-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </span>

                    <h3 className="fs-2 fw-semibold mb-0 ms-4" id={`filter-menu-${filter.name}`}>
                      {filter.name}
                    </h3>
                  </div>

                  <div id={filter.name} className={`fs-6 collapse ps-10`}>
                    {(filter?.groups || []).map((option) => {
                      return <div key={option.name} className={''}>
                        <div
                          className="accordion-header py-3 d-flex collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target={`#${option.name}`}>
                          <span className="accordion-icon">
                            <i className="ki-duotone ki-arrow-right fs-5">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </span>

                          <h3 className="fs-4 fw-semibold mb-0 ms-4">
                            {option.name}
                          </h3>
                        </div>

                        <div id={option.name} className={`fs-6 collapse ps-10`}>
                          <FilterOption
                            options={option.fields}
                          />
                        </div>

                      </div>
                    })}

                    <FilterOption
                      options={filter.fields}
                    />
                  </div>
                </div>

              </div>
            })}


          </div>

        </div>

        <div className={'card-footer d-flex justify-content-end'}>
          <button
            type="button"
            className="btn btn-light me-3"
            onClick={clear}
          >{intl.formatMessage({ id: 'PLACEHOLDERS.CLEAR' })}
          </button>
        </div>
      </div>
    </div>
  )
}

export { SearchDrawer }
