import {useEffect, useState} from "react";
import ITalent from "../core/interfaces/ITalent";
import getProfilePicture from "../core/utils/getProfilePicture";
import updateCompanyPackageTalentStatusRequest from "../core/api/requests/companies/package/updateCompanyPackageTalentStatusRequest";
import {useDispatch, useSelector} from "react-redux";
import convertToFraction from "../core/utils/convertToFraction";
import {RootState} from "../core/store/store.ts";
import tlt from "../core/api/helpers/translator/translator.ts";

export interface IComponent {
  talent: ITalent;
  companyId?:string
  packageId?:string
  showDetails?: boolean
  allowSelect?: boolean
  onSelect?: (talent:ITalent) => void
}

export default function TalentBigButton(props: IComponent) {
  const dispatch = useDispatch()
  const [ imageUri, setImageUri ] = useState<string>('')
  const [ statusSelection, setStatusSelection] = useState<string>('')

  const pkg = useSelector((state: RootState) => state.package)

  useEffect(() => {
    const u = getProfilePicture(props.talent)
    setImageUri(u)

    const guestInformation = pkg?.package?.recipients?.find((t: any) => t.recipient?.email === pkg.guestEmail)
    if (!guestInformation) return

    const tltSelection = guestInformation?.talentStatus?.find((t: any) => t.talentId === props.talent.id)
    if (!tltSelection) return

    setStatusSelection(tltSelection.status)
  }, [props.talent]);

  const redirectToTalent = () => {
    if (props.talent.id) {
      if(!props.showDetails){
        window.open(`/talents/${props.talent.id}`, '_blank')
      } else if(props.onSelect) {
        props?.onSelect(props.talent)
      }
    }
  }

  const changeStatus = async (status: string) => {
    if (status === 'pending') return

    console.log('status', status)
    setStatusSelection(status)

    if(props.companyId && props.packageId && props.talent.id){
      await updateCompanyPackageTalentStatusRequest(
        dispatch,
        props.companyId,
        props.packageId,
        props.talent.id,
        status,
        pkg?.guestEmail || '',
      );
    }
  }

  useEffect(() => {
    const guestInformation = pkg?.package?.recipients?.find((t: any) => t.recipient?.email === pkg.guestEmail)
    if (!guestInformation) return

    const tltSelection = guestInformation?.talentStatus?.find((t: any) => t.talentId === props.talent.id)
    if (!tltSelection) return

    const sts = tltSelection.status
    setStatusSelection('like')
  }, []);

  const {measurements} = props.talent?.talent?.physical || {}

  return <div className={'talent-super-container col-6 col-md-4 col-lg-3 col-xl-2'}>
    <div className={`talent-container mb-1`}>
      <div className={'talent-background'} style={{backgroundImage: `url(${imageUri})`}} onClick={redirectToTalent}>
        {props.showDetails && 
          <div className={'talent-info position-absolute'}>
              <span className="talent-name text-capitalize fw-bold">{props.talent.name} {props.talent.lastName}</span>
              <span className="talent-height text-capitalize">{measurements?.height ? `${measurements.height}cm / ${convertToFraction(measurements.height)}` : ''}</span>
          </div>
        }
      </div>  
      {props.allowSelect &&
        <div className={'rc-talent-button-container text-center'}>
          <button onClick={() => statusSelection === 'like' ?  changeStatus('maybe') : changeStatus('like')} className={`${statusSelection} ${['like', 'maybe'].includes(statusSelection) ? 'selected' : ''}`}>
            <i className="fa-solid fa-check"></i>
          </button>
          <button onClick={() => changeStatus('unlike')} className={`unlike ${statusSelection === 'unlike' ? 'selected' : ''}`}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
      }
    </div>
  </div>
}
