import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import PasswordChange from "./components/PasswordChange.tsx";
import AccountActivation from "./components/AccountActivation.tsx";
import ValidateAccount from './components/ValidateAccount.tsx'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />

      <Route path='signup' element={<Registration />} />

      <Route path='validate-account' element={<ValidateAccount />} />

      <Route path='forgot-password' element={<ForgotPassword />} />

      <Route path='password-change' element={<PasswordChange />} />

      <Route path='account-activation' element={<AccountActivation />} />

      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
